import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from '../config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { PuffLoader } from 'react-spinners';

export default function Home() {
    const [customers, setCustomers] = useState(null);
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {

        // fetch list of customers
        fetch(config.api.host + ":" + config.api.port + config.api.prefix + "/customers")
            .then((res) => res.json())
            .then((data) => {
                setCustomers(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        // subscribe to real-time Server-Sent Event statistics 
        const sse = new EventSource(config.api.host + ":" + config.api.port + config.api.prefix + "/jobs/statistics");
        sse.onopen = () => {
            // setConnected(true);
        };
        sse.onmessage = (msg) => {
            setStatistics(JSON.parse(msg.data));
        };
        sse.onerror = (err) => {
            console.log("error: " + err);
            sse.close();
            // setConnected(false);
        };

        // cleanup
        return () => {
            sse.close();
            // setConnected(false);
        };
    }, [/* we only want to execute this effect once*/]);

    if (!customers || !statistics) {
        return (
            <div style={{ position: "absolute", marginLeft: "-65px", marginTop: "-65px", scale: "2", top: "50%", left: "50%" }}>
                <PuffLoader />
            </div>
        );
    } else {
        return (
            <>
                <JobsStatistics
                    customers={customers}
                    statistics={statistics}
                />
            </>
        );
    }
}

const jobStatus = {
    PENDING: "pending",
    PROCESSING: "processing",
    COMPLETED: "completed",
    FAILED: "failed",
    ARCHIVED: "archived",
};

const statuses = [jobStatus.PENDING, jobStatus.PROCESSING, jobStatus.COMPLETED, jobStatus.FAILED, jobStatus.ARCHIVED];

function JobsStatistics({ customers, statistics }) {

    // compute totals
    let totals = [];
    for (const status of statuses)
        totals[status] = 0;
    for (const row of statistics)
        totals[row.status] += row.count;

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <TotalsStatistics totals={totals} />
            {
                customers.map((customer) => (
                    <CustomerStatistics
                        key={customer.id}
                        customer={customer}
                        statistics={statistics}
                    />
                ))
            }
        </div>
    );

}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

function TotalsStatistics({ totals }) {

    return (
        <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Paper elevation={2} sx={{ border: "1px solid black", borderRadius: "8px" }}>
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table sx={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key="total"
                                    align="center"
                                    colSpan={statuses.length}
                                    style={{ fontWeight: "bolder", color: "white", backgroundColor: "black" }}
                                >
                                    <Link style={{color: "white"}}to={"/jobs"}>All Jobs</Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {
                                    statuses.map((status) => (
                                        <TableCell
                                            key={status}
                                            align="center"
                                            style={{ fontSize: 10 }}
                                        >
                                            <img
                                                style={{ marginBottom: -12, marginLeft: 8, marginRight: 8, width: 24 }}
                                                src={require('../assets/' + status + '.png')}
                                                alt=""
                                            />
                                            <br />
                                            <p style={{ marginBottom: -12 }}>{status}</p>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {
                                    statuses.map((status) => (
                                        <StyledTableCell
                                            key={status}
                                            align="center"
                                            style={{ minWidth: 100, fontSize:"20px" }}
                                        >
                                            {totals[status]}
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper >
        </div>
    )
}

function CustomerStatistics({ customer, statistics }) {
    let counts = [];
    for (const status of statuses) {
        const row = statistics.find((row) => {
            return row.customer_id === customer.id && row.status === status;
        });
        counts[status] = row ? row.count : 0;
    }

    return (
        <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Paper elevation={2} sx={{ border: "1px solid black", borderRadius: "8px" }}>
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key={customer.id}
                                    align="center"
                                    colSpan={statuses.length}
                                    style={{ fontWeight: "bolder", color: "black", backgroundColor: "lightgrey" }}
                                >
                                    <Link style={{color: "black"}}to={"/jobs/" + customer.id}>{customer.name}</Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {
                                    statuses.map((status) => (
                                        <TableCell
                                            key={status}
                                            align="center"
                                            style={{ fontSize: 10 }}
                                        >
                                            <img
                                                style={{ marginBottom: -12, marginLeft: 8, marginRight: 8, width: 24 }}
                                                src={require('../assets/' + status + '.png')}
                                                alt=""
                                            />
                                            <br />
                                            <p style={{ marginBottom: -12 }}>{status}</p>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {
                                    statuses.map((status) => (
                                        <StyledTableCell
                                            key={status}
                                            align="center"
                                            style={{ minWidth: 100, fontSize: "20px" }}
                                        >
                                            {counts[status]}
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}
